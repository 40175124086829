import { getAPIBaseUrl } from '@/utils/url';
import client from './axios';
import { GetTeacherInfoRes, TrailReportDetail } from './types/trialStudyReport';
import { COMMON_API_HOST } from '@/utils/env';

const COMMON_API = getAPIBaseUrl(COMMON_API_HOST);
/**
 * 获取老师信息
 * @returns GetTeacherInfoRes
 */
export const getTeacherInfo = () => {
  return client.get<GetTeacherInfoRes, GetTeacherInfoRes>(`/api/reserve/gs_teacher_info_V2`);
};

/**
 * 获取学习报告信息
 * @returns GetTeacherInfoRes
 */
export const getTrialStudyReportInfo = (trackId: string) => {
  return client.get<unknown, TrailReportDetail & { reportDetail: { reportJsonData: string } }>(
    `${COMMON_API}/public/lead/${trackId}/study_report`,
    {
      skipErrorHandler: true,
    },
  );
};
