/* eslint-disable @next/next/no-img-element */
import style from './style.module.scss';
// import { useRouter } from 'next/router';
import { traceEvent } from '@wk/wk-gatherer';
import useUser from '@/store/useUser';
export interface IProps {
  subject: 'CHINESE' | 'MATH' | 'ENGLISH';
}
import Cookie from 'js-cookie';
import { useTranslation } from 'next-i18next';
import { goWebSite } from '@/utils/goWebSite';
import classNames from 'classnames';
const Expectations: React.FC<IProps> = ({ subject }: IProps) => {
  const { t } = useTranslation(['home', 'appointment']);
  // const router = useRouter();
  const { account } = useUser();
  const subjecLangMap = {
    CHINESE: t('home:中文'),
    MATH: t('home:数学'),
    ENGLISH: t('home:英文'),
  };
  const expectationList = {
    CHINESE: [
      { label: t('appointment:中文-开拓升学/职业前景'), icon: '/imgs/preparations/education.svg', tag: 'a' },
      { label: t('appointment:中文-了解中华文化'), icon: '/imgs/preparations/culture.svg', tag: 'b' },
      { label: t('appointment:中文-培养兴趣爱好'), icon: '/imgs/preparations/hobby.svg', tag: 'c' },
    ],
    MATH: [
      { label: t('appointment:数学-提升数学成绩'), icon: '/imgs/preparations/education.svg', tag: 'a' },
      { label: t('appointment:数学-参加AMC、袋鼠竞赛等数学竞赛'), icon: '/imgs/preparations/culture.svg', tag: 'b' },
      { label: t('appointment:数学-提升和培养逻辑思维能力'), icon: '/imgs/preparations/hobby.svg', tag: 'c' },
    ],
    ENGLISH: [
      { label: t('appointment:英文-提高校内成绩'), icon: '/imgs/preparations/education.svg', tag: 'a' },
      { label: t('appointment:英文-提高写作水平'), icon: '/imgs/preparations/culture.svg', tag: 'b' },
      { label: t('appointment:英文-提高阅读水平'), icon: '/imgs/preparations/hobby.svg', tag: 'c' },
    ],
  };

  return (
    <>
      <div
        className={classNames(
          style.tile,
          'mobile:mx-auto mobile:mt-[24px] mobile:box-border mobile:w-[calc(100vw-32px)] mobile:rounded-t-[12px] mobile:bg-white mobile:px-[16px] mobile:pb-[4.2666666667vmin] mobile:pt-[16px] mobile:text-[4.8vmin] mobile:font-bold pc:mb-[20px] pc:text-[16px]  pc:font-extrabold',
        )}
      >
        {t('appointment:自主化-预约1节免费试听课')}
      </div>
      <div
        className={classNames(
          style.expectationsWrap,
          'last:mb-0 mobile:mx-auto mobile:mb-0 mobile:box-border mobile:w-[calc(100vw-32px)] mobile:bg-white',
        )}
      >
        <p className={style.description}>{t('appointment:自主化-科目期望', { subject: subjecLangMap[subject] })}</p>
        <div className={classNames(style.expectations, 'pc:flex')}>
          {expectationList[subject].map((item) => {
            return (
              <div
                className={classNames(style.expectationsItem, 'mobile:mb-[12px]')}
                key={item.label}
                onClick={() => {
                  const curSubject = window.sessionStorage.getItem('curSubject');
                  traceEvent({
                    data: {
                      _event: `U_HomePage_subject_BookingStepClick`,
                      subject: subject ? subject : curSubject,
                      options: item.label,
                      account_id: account?.uuid,
                      trigger_pvid: Cookie.get('trigger_pvid'),
                      login_triggerevent: Cookie.get('login_triggerevent'),
                      login_method: Cookie.get('login_type'),
                    },
                  });
                  // router.push(
                  //   `/home/independent-appointment?subject=${subject.toLowerCase()}&expectation=${
                  //     item.tag
                  //   }&booking_triggerevent=U_HomePage_subject_BookingStepClick`,
                  // );
                  goWebSite(
                    `independent-appointment?subject=${subject.toLowerCase()}&expectation=${
                      item.tag
                    }&booking_triggerevent=U_HomePage_subject_BookingStepClick`,
                  );
                }}
              >
                <img src={item.icon} alt={item.label} />
                {item.label}
              </div>
            );
          })}
          <div
            className={classNames(
              style.expectationsMore,
              'mobile:justify-start mobile:px-[12px] pc:w-[94px] pc:justify-center',
            )}
            onClick={() => {
              const curSubject = window.sessionStorage.getItem('curSubject');
              traceEvent({
                data: {
                  _event: `U_HomePage_subject_BookingStepClick`,
                  subject: subject ? subject : curSubject,
                  options: t('appointment:自主化-学员中心-其他'),
                  account_id: account?.uuid,
                  trigger_pvid: Cookie.get('trigger_pvid'),
                  login_triggerevent: Cookie.get('login_triggerevent'),
                  login_method: Cookie.get('login_type'),
                },
              });
              // router.push(
              //   `/home/independent-appointment?subject=${subject.toLowerCase()}&booking_triggerevent=U_HomePage_subject_BookingStepClick`,
              // );
              goWebSite(
                `independent-appointment?subject=${subject.toLowerCase()}&booking_triggerevent=U_HomePage_subject_BookingStepClick`,
              );
            }}
          >
            {t('appointment:自主化-学员中心-其他')}
            <img src="/imgs/preparations/double_arrow.svg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Expectations;
